import React from 'react';
import './icons.scss';

const RepresentativesIcon = () => {
    return (
        <div className="icon representativesIcon">
            <div className="inner">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                        d="M7.846 9.025c2.882-1.036 5.039-1.032 7.92.003a1.72 1.72 0 011.15 1.467c.052.489.103 1.095.154 1.726l.073.952.101 1.341a1.712 1.712 0 01-.507 1.374 10.04 10.04 0 01-.169.163l-.254.24c-.476.445-1.166 1.079-1.35 1.252v4.705c0 .964-.787 1.752-1.75 1.752a1.75 1.75 0 01-1.4-.703c-.64.852-1.88.947-2.637.189a1.748 1.748 0 01-.514-1.238v-4.565c-.552-.464-1.243-1.242-1.776-1.775a1.718 1.718 0 01-.504-1.402c.092-.984.215-2.966.312-3.997.065-.69.5-1.25 1.151-1.484zm14.433-1.85c.779.278 1.297.94 1.384 1.76.119 1.127.235 2.8.33 4.03a2.062 2.062 0 01-.61 1.648c-.331.33-1.124 1.15-1.558 1.545l-.118.104v5.635A2.11 2.11 0 0119.6 24a2.101 2.101 0 01-1.404-.538c-.626.56-1.492.68-2.221.373.24-.408.39-.874.423-1.372l.007-.215V18.15l.244-.224.383-.355c.337-.312.642-.596.74-.694a3.12 3.12 0 00.915-2.47c-.1-1.298-.206-2.842-.334-4.059a3.146 3.146 0 00-.652-1.626c-.775-.987-2.267-1.348-3.451-1.605 2.893-1.01 5.112-.988 8.029.057zM9.563 7.072c-.712.142-1.443.353-2.219.632a3.097 3.097 0 00-2.07 2.668c-.1 1.077-.228 3.11-.311 4.005-.087.938.244 1.859.903 2.52.475.475.753.788 1.153 1.187l.212.208v3.955c0 .6.175 1.184.488 1.682a2.09 2.09 0 01-1.935-.468 2.09 2.09 0 01-2.882-.08 2.101 2.101 0 01-.608-1.295l-.008-.19v-5.589L.613 14.633a2.074 2.074 0 01-.603-1.681c.04-.442.09-1.104.14-1.793l.05-.69c.041-.57.083-1.114.12-1.518a2.07 2.07 0 011.381-1.78c2.86-1.029 5.046-1.059 7.861-.098zm2.25-5.573a2.812 2.812 0 110 5.625 2.812 2.812 0 010-5.625zm-6-1.5a2.812 2.812 0 110 5.625 2.812 2.812 0 010-5.625zm12.375 0a2.812 2.812 0 110 5.625 2.812 2.812 0 010-5.625z"
                        fillRule="nonzero"
                    />
                </svg>
            </div>
        </div>
    );
};

export default RepresentativesIcon;
