import React from 'react';
import './icons.scss';

const RepublicanIcon = () => {
    return (
        <div className="icon republicanIcon">
            <div className="inner">
                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
                    <path fill="none" d="M0 0h32v32H0z" />
                    <path
                        d="M31.2 19.108h-1.6c-.44 0-.8.35-.8.777v3.108a.79.79 0 01-.8.777.79.79 0 01-.8-.777v-5.44H0v8.549c0 .427.36.777.8.777h4.8c.44 0 .8-.35.8-.777v-3.886H16v3.886c0 .427.36.777.8.777h4.8c.44 0 .8-.35.8-.777v-5.44H24v2.103c0 2.03 1.5 3.89 3.58 4.094 2.39.239 4.42-1.588 4.42-3.866v-3.108a.79.79 0 00-.8-.777zm-4-6.217c0-4.293-3.58-7.771-8-7.771H8c-4.42 0-8 3.478-8 7.77V16h27.2V12.89zM8.815 11.84l-.99.939.235 1.326c.04.238-.215.417-.435.306L6.4 13.784l-1.225.628c-.215.11-.475-.068-.435-.306l.235-1.326-.99-.939a.288.288 0 01.165-.495l1.37-.193.61-1.205a.302.302 0 01.535 0l.61 1.205 1.37.193a.29.29 0 01.17.495zm7.2 0l-.99.939.235 1.326c.04.238-.215.417-.435.306l-1.225-.628-1.225.628c-.215.11-.475-.068-.435-.306l.235-1.326-.99-.939a.288.288 0 01.165-.495l1.37-.193.61-1.205a.302.302 0 01.535 0l.61 1.205 1.37.193a.29.29 0 01.17.495zm7.2 0l-.99.939.235 1.326c.04.238-.215.417-.435.306l-1.225-.628-1.225.628c-.215.11-.475-.068-.435-.306l.235-1.326-.99-.939a.288.288 0 01.165-.495l1.37-.193.61-1.205a.302.302 0 01.535 0l.61 1.205 1.37.193a.29.29 0 01.17.495z"
                        fillRule="nonzero"
                    />
                </svg>
            </div>
        </div>
    );
};

export default RepublicanIcon;
