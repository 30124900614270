import React from 'react';
import './icons.scss';

const DropBoxIcon = () => {
    return (
        <div className="icon dropBoxIcon">
            <div className="inner">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                        d="M12.012 17.355l11.502 6.61a.7.7 0 01-.217.035H.703a.705.705 0 01-.166-.02l11.475-6.625zM0 10.435l10.598 6.12L0 22.672V10.435zm24-.001v12.189l-10.58-6.081L24 10.434zM19.406 0c.387 0 .703.316.703.703v10.36L12 15.744l-8.11-4.683V.703c0-.387.315-.703.702-.703zm-7.578 4.835a.703.703 0 00-.992-.062l-.068.06a.7.7 0 00-.456-.169H6a.703.703 0 00-.703.703V9.68c0 .388.315.703.703.703h4.313a.703.703 0 00.703-.703V6.489l.75-.662a.703.703 0 00.062-.992zm9.687 3.171l1.81 1.201-1.81 1.045V8.006zM2.484 7.983v2.266L.674 9.204l1.81-1.22zm6.88-1.913l-1.057.932-.034-.035a.703.703 0 00-.995.995l.502.501a.701.701 0 00.962.03l.867-.764v1.248H6.703V6.07h2.661zm7.2 1.29h-2.689a.703.703 0 000 1.406h2.688a.703.703 0 000-1.407zm1.434-2.813h-4.123a.703.703 0 000 1.406h4.123a.703.703 0 000-1.406z"
                        fillRule="nonzero"
                    />
                </svg>
            </div>
        </div>
    );
};

export default DropBoxIcon;
