import React from 'react';
import './icons.scss';

const MapBoxIcon = () => {
    return (
        <div className="icon mapBoxIcon">
            <div className="inner">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 800 180">
                    <path d="M594.6 49.8c-9.9 0-19.4 4.1-26.3 11.3V23c0-1.2-1-2.2-2.2-2.2h-13.4c-1.2 0-2.2 1-2.2 2.2v103c0 1.2 1 2.2 2.2 2.2h13.4c1.2 0 2.2-1 2.2-2.2v-7.1c6.9 7.2 16.3 11.3 26.3 11.3 20.9 0 37.8-18 37.8-40.2s-16.9-40.2-37.8-40.2zm-3.1 64.3c-12.7 0-23-10.6-23.1-23.8v-.6c.2-13.2 10.4-23.8 23.1-23.8 12.8 0 23.1 10.8 23.1 24.1s-10.4 24.1-23.1 24.1zM681.7 49.8c-22.6 0-40.9 18-40.9 40.2s18.3 40.2 40.9 40.2c22.6 0 40.9-18 40.9-40.2s-18.3-40.2-40.9-40.2zm-.1 64.3c-12.8 0-23.1-10.8-23.1-24.1s10.4-24.1 23.1-24.1 23.1 10.8 23.1 24.1-10.4 24.1-23.1 24.1zM431.6 51.8h-13.4c-1.2 0-2.2 1-2.2 2.2v7.1c-6.9-7.2-16.3-11.3-26.3-11.3-20.9 0-37.8 18-37.8 40.2s16.9 40.2 37.8 40.2c9.9 0 19.4-4.1 26.3-11.3v7.1c0 1.2 1 2.2 2.2 2.2h13.4c1.2 0 2.2-1 2.2-2.2V54c0-1.2-1-2.2-2.2-2.2zm-38.8 62.3c-12.8 0-23.1-10.8-23.1-24.1s10.4-24.1 23.1-24.1c12.7 0 23 10.6 23.1 23.8v.6c-.1 13.2-10.4 23.8-23.1 23.8zM498.5 49.8c-9.9 0-19.4 4.1-26.3 11.3V54c0-1.2-1-2.2-2.2-2.2h-13.4c-1.2 0-2.2 1-2.2 2.2v103c0 1.2 1 2.2 2.2 2.2H470c1.2 0 2.2-1 2.2-2.2v-38.1c6.9 7.2 16.3 11.3 26.3 11.3 20.9 0 37.8-18 37.8-40.2s-16.9-40.2-37.8-40.2zm-3.1 64.3c-12.7 0-23-10.6-23.1-23.8v-.6c.2-13.2 10.4-23.8 23.1-23.8 12.8 0 23.1 10.8 23.1 24.1s-10.3 24.1-23.1 24.1zM311.8 49.8c-10 .1-19.1 5.9-23.4 15-4.9-9.3-14.7-15.1-25.2-15-8.2 0-15.9 4-20.7 10.6V54c0-1.2-1-2.2-2.2-2.2h-13.4c-1.2 0-2.2 1-2.2 2.2v72c0 1.2 1 2.2 2.2 2.2h13.4c1.2 0 2.2-1 2.2-2.2V82.9c.5-9.6 7.2-17.3 15.4-17.3 8.5 0 15.6 7.1 15.6 16.4v44c0 1.2 1 2.2 2.2 2.2h13.5c1.2 0 2.2-1 2.2-2.2l-.1-44.8c1.2-8.8 7.5-15.6 15.2-15.6 8.5 0 15.6 7.1 15.6 16.4v44c0 1.2 1 2.2 2.2 2.2h13.5c1.2 0 2.2-1 2.2-2.2l-.1-49.5c0-14.8-12.6-26.7-28.1-26.7zM794.7 125.1l-23.2-35.3 23-35c.6-.9.3-2.2-.6-2.8-.3-.2-.7-.3-1.1-.3h-15.5c-1.2 0-2.3.6-2.9 1.6L760.9 76l-13.5-22.6c-.6-1-1.7-1.6-2.9-1.6H729c-1.1 0-2 .9-2 2 0 .4.1.8.3 1.1l23 35-23.2 35.3c-.6.9-.3 2.2.6 2.8.3.2.7.3 1.1.3h15.5c1.2 0 2.3-.6 2.9-1.6l13.8-23 13.8 23c.6 1 1.7 1.6 2.9 1.6H793c1.1 0 2-.9 2-2 0-.4-.1-.8-.3-1.2z" />
                    <g>
                        <path d="M93.9 1.1C44.8 1.1 5 40.9 5 90s39.8 88.9 88.9 88.9 88.9-39.8 88.9-88.9c0-49.1-39.8-88.9-88.9-88.9zm42.2 110.7c-30.4 30.4-84.7 20.7-84.7 20.7s-9.8-54.2 20.7-84.7C89 30.9 117 31.6 134.7 49.2s18.3 45.7 1.4 62.6z" />
                        <path d="M104.1 53.2l-8.7 17.9-17.9 8.7 17.9 8.7 8.7 17.9 8.7-17.9 17.9-8.7-17.9-8.7z" />
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default MapBoxIcon;
