import React from 'react';
import './icons.scss';

const SearchIcon = () => {
    return (
        <div className="icon searchIcon">
            <div className="inner">
                <svg viewBox="0 0 29 29" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
                    <path d="M.5 11.817c0-1.225.191-2.415.574-3.567a10.81 10.81 0 011.621-3.12A12.213 12.213 0 015.13 2.695a10.81 10.81 0 013.12-1.621A11.237 11.237 0 0111.817.5c1.226 0 2.415.191 3.568.574 1.152.383 2.192.923 3.119 1.621A12.218 12.218 0 0120.94 5.13a10.832 10.832 0 011.62 3.12c.383 1.152.574 2.342.574 3.567 0 2.191-.605 4.228-1.815 6.113l7.009 7.008a.571.571 0 01.172.42.607.607 0 01-.172.43l-2.54 2.54a.574.574 0 01-.425.172.578.578 0 01-.425-.172l-7.008-7.009c-1.869 1.21-3.907 1.815-6.113 1.815-1.225 0-2.415-.191-3.567-.574a10.832 10.832 0 01-3.12-1.62 12.218 12.218 0 01-2.435-2.436 10.817 10.817 0 01-1.621-3.119A11.243 11.243 0 01.5 11.817zm3.596 0a7.58 7.58 0 001.034 3.872 7.64 7.64 0 002.815 2.815 7.58 7.58 0 003.872 1.034 7.58 7.58 0 003.872-1.034 7.633 7.633 0 002.815-2.815 7.58 7.58 0 001.034-3.872 7.58 7.58 0 00-1.034-3.872 7.64 7.64 0 00-2.815-2.815 7.58 7.58 0 00-3.872-1.034A7.58 7.58 0 007.945 5.13 7.648 7.648 0 005.13 7.945a7.58 7.58 0 00-1.034 3.872z" />
                </svg>
            </div>
        </div>
    );
};

export default SearchIcon;
