import React from 'react';
import './icons.scss';

const BallotIcon = () => {
    return (
        <div className="icon ballotIcon">
            <div className="inner">
                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
                    <path fill="none" d="M0 0h32.962v33.224H0z" />
                    <clipPath id="a">
                        <path d="M0 0h32.962v33.224H0z" />
                    </clipPath>
                    <g clipPath="url(#a)">
                        <g transform="scale(1.03007 1.03823)">
                            <path d="M3.086 31.062c0 .518.482.938 1.077.938h23.674c.595 0 1.077-.42 1.077-.938v-8.124H3.086v8.124zM30.923 14.75H1.077c-.595 0-1.077.42-1.077.938v4.437c0 .518.482.938 1.077.938h29.846c.595 0 1.077-.42 1.077-.938v-4.437c0-.518-.482-.938-1.077-.938zM26.33.938C26.33.42 25.848 0 25.254 0H6.737C6.142 0 5.66.42 5.66.938v11.937h20.67V.938zM14.202 7.895l-2.53 2.243-.061.058a1.192 1.192 0 01-1.522 0l-.659-.574a.855.855 0 010-1.326c.384-.334.986-.363 1.408-.087l1.833-1.625a1.19 1.19 0 011.518-.011c.423.362.429.954.013 1.322zm7.822 2.26h-3.518c-.594 0-1.076-.42-1.076-.938 0-.517.482-.937 1.076-.937h3.518c.594 0 1.077.42 1.077.937 0 .518-.483.938-1.077.938zm0-4.875h-3.518c-.594 0-1.076-.42-1.076-.938 0-.517.482-.937 1.076-.937h3.518c.594 0 1.077.42 1.077.937 0 .518-.483.938-1.077.938zm-12.057-1c0-.516.484-.938 1.076-.938.592 0 1.077.422 1.077.938 0 .515-.485.937-1.077.937s-1.076-.422-1.076-.937z" />
                            <clipPath id="b">
                                <path d="M3.086 31.062c0 .518.482.938 1.077.938h23.674c.595 0 1.077-.42 1.077-.938v-8.124H3.086v8.124zM30.923 14.75H1.077c-.595 0-1.077.42-1.077.938v4.437c0 .518.482.938 1.077.938h29.846c.595 0 1.077-.42 1.077-.938v-4.437c0-.518-.482-.938-1.077-.938zM26.33.938C26.33.42 25.848 0 25.254 0H6.737C6.142 0 5.66.42 5.66.938v11.937h20.67V.938zM14.202 7.895l-2.53 2.243-.061.058a1.192 1.192 0 01-1.522 0l-.659-.574a.855.855 0 010-1.326c.384-.334.986-.363 1.408-.087l1.833-1.625a1.19 1.19 0 011.518-.011c.423.362.429.954.013 1.322zm7.822 2.26h-3.518c-.594 0-1.076-.42-1.076-.938 0-.517.482-.937 1.076-.937h3.518c.594 0 1.077.42 1.077.937 0 .518-.483.938-1.077.938zm0-4.875h-3.518c-.594 0-1.076-.42-1.076-.938 0-.517.482-.937 1.076-.937h3.518c.594 0 1.077.42 1.077.937 0 .518-.483.938-1.077.938zm-12.057-1c0-.516.484-.938 1.076-.938.592 0 1.077.422 1.077.938 0 .515-.485.937-1.077.937s-1.076-.422-1.076-.937z" />
                            </clipPath>
                            <g clipPath="url(#b)">
                                <path
                                    d="M3.086 31.062c0 .518.482.938 1.077.938h23.674c.595 0 1.077-.42 1.077-.938v-8.124H3.086v8.124zM30.923 14.75H1.077c-.595 0-1.077.42-1.077.938v4.437c0 .518.482.938 1.077.938h29.846c.595 0 1.077-.42 1.077-.938v-4.437c0-.518-.482-.938-1.077-.938zM26.33.938C26.33.42 25.848 0 25.254 0H6.737C6.142 0 5.66.42 5.66.938v11.937h20.67V.938zM14.202 7.895l-2.53 2.243-.061.058a1.192 1.192 0 01-1.522 0l-.659-.574a.855.855 0 010-1.326c.384-.334.986-.363 1.408-.087l1.833-1.625a1.19 1.19 0 011.518-.011c.423.362.429.954.013 1.322zm7.822 2.26h-3.518c-.594 0-1.076-.42-1.076-.938 0-.517.482-.937 1.076-.937h3.518c.594 0 1.077.42 1.077.937 0 .518-.483.938-1.077.938zm0-4.875h-3.518c-.594 0-1.076-.42-1.076-.938 0-.517.482-.937 1.076-.937h3.518c.594 0 1.077.42 1.077.937 0 .518-.483.938-1.077.938zm-12.057-1c0-.516.484-.938 1.076-.938.592 0 1.077.422 1.077.938 0 .515-.485.937-1.077.937s-1.076-.422-1.076-.937z"
                                    fillRule="nonzero"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default BallotIcon;
